export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const CHANGE_LIST_FORM = "CHANGE_LIST_FORM";
export const CHANGE_SERVICE_SELECTED = "CHANGE_SERVICE_SELECTED";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PLAN_IMAGES = "LOAD_PLAN_IMAGES";

export const showLoading = (dispatch) => {
  return dispatch({ type: SHOW_LOADING });
};

export const hideLoading = (dispatch) => {
  return dispatch({ type: HIDE_LOADING });
};

export const changeServiceSelected = (dispatch, service) => {
  return dispatch({ type: CHANGE_SERVICE_SELECTED, service });
};

export const loadProducts = (dispatch, products) => {
  return dispatch({ type: LOAD_PRODUCTS, products });
};

export const loadPlanImages = (dispatch, plan1, plan2) => {
  return dispatch({ type: LOAD_PLAN_IMAGES, plan1, plan2 });
};
