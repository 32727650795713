import {
  HIDE_LOADING,
  LOAD_PLAN_IMAGES,
  LOAD_PRODUCTS,
  SHOW_LOADING,
} from "../actions";

export const initialState = {
  loading: false,
  products: [],
  plan1: [],
  plan2: [],
};

export const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SHOW_LOADING:
      return { ...state, loading: true };
    case HIDE_LOADING:
      return { ...state, loading: false };
    case LOAD_PRODUCTS:
      return { ...state, products: [...actions.products] };
    case LOAD_PLAN_IMAGES:
      return { ...state, plan1 : [...actions.plan1] ,  plan2 : [...actions.plan2]};
    default:
      return initialState;
  }
};
