export const FIRE_BASE_CONFIGS = {
  apiKey: "AIzaSyDB9hwMt6gGQzNzhD0ajCKuMRzjdknzYlY",
  authDomain: "loup-engineering.firebaseapp.com",
  databaseURL: "https://loup-engineering.firebaseio.com",
  projectId: "loup-engineering",
  storageBucket: "loup-engineering.appspot.com",
  messagingSenderId: "303199085641",
  appId: "1:303199085641:web:47aae134ad553db1c90b98",
  measurementId: "G-ZJSR98LT7Q",
};
