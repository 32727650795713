import React, { useContext, useEffect, useState } from "react";
import "./Products.scss";
import { useTranslation } from "react-i18next";
import AppContext from "../context/app-context";
import Modal from "@bit/danielm2048.debate-app.modal";
import ModalContent from "@bit/danielm2048.debate-app.modal-content";
import ModalActions from "@bit/danielm2048.debate-app.modal-actions";
import Close from "@bit/danielm2048.debate-app.close";
import { Carousel } from "react-responsive-carousel";

const Products = () => {
  const { products } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const toggle = () => {
    setModal(false);
    setSelectedProduct(null);
  };

  const onClickShowProduct = (product) => {
    setModal(true);
    setSelectedProduct(product);
  };

  return (
    <div className="products">
      <div className="section-1">
        <img src="/assets/images/project/00.jpg" alt="bg-1" />
      </div>
      <div className="mx-auto product-previews col-12 col-md-10">
        <div>
          <h3>Referenzprojekte</h3>
          <div>Hier haben wir eine kleine Zusammenstellung von Projekten</div>
        </div>
        <div className="row mt-4">
          <div></div>
          {products
            ? products.map((product) => (
                <div data-aos="fade-up" className="col-12 col-sm-6 col-lg-3">
                  <div
                    onClick={() => onClickShowProduct(product)}
                    className="image-container d-flex align-items-center cursor-pointer"
                    style={{
                      backgroundImage: `url(${product.images[0].url})`,
                    }}
                  >
                    <div className="product-name pl-3">
                      <h3>{product.name}</h3>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <Modal modal={modal} className="product-modal">
        <ModalContent>
          <Close onClick={toggle} title="Close">
            &times;
          </Close>
          <ModalActions class="product-modal-content">
            {selectedProduct ? (
              <div className="mt-4">
                <Carousel
                  showIndicators
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                >
                  {selectedProduct.images.map((image) => (
                    <div key={image.id}>
                      <img src={image.url} alt={`product-${image.id}`} />
                    </div>
                  ))}
                </Carousel>
                <h5 className="mt-2">{selectedProduct.name}</h5>
                <div className="content">
                  <div className="d-flex">
                    <div className="col-2 pl-0">Standorte</div>
                    <div className="col-8">: {selectedProduct.location}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-2 pl-0">BGF Gesamt</div>
                    <div className="col-8">: {selectedProduct.gfaTotal}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-2 pl-0">Leistungen</div>
                    <div className="col-8">
                      {selectedProduct.services
                        .split("|")
                        .map((item, index) => (
                          <div key={index}>: {item}</div>
                        ))}
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedProduct.description,
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              ""
            )}
          </ModalActions>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Products;
