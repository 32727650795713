import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import home_de from "./translations/de/home";
import home_en from "./translations/en/home";
import products_de from "./translations/de/products";
import products_en from "./translations/en/products";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        home: home_en,
        products: products_en,
      },
      de: {
        home: home_de,
        products: products_de,
      },
    },
    fallbackLng: "de",
    lng: localStorage.getItem('lang') || "de",
    debug: true,
    ns: ["home"],
    defaultNS: "home",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
