import React from "react";
import { Carousel } from "react-responsive-carousel";

const Banners = () => {
  const banners = Array(18).fill(0);

  return (
    <Carousel
      showIndicators
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
    >
      {banners.map((_, index) => (
        <div key="index">
          <img
            src={`/assets/images/banner/${index}.jpg`}
            alt={`banner-${index}`}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default Banners;
