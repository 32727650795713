import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "./Plan.scss";
import AppContext from "../context/app-context";
const Plan = () => {
  const { plan1 } = useContext(AppContext);

  return (
    <div className="plans">
      <div className="section-1">
        <img src="/assets/images/plan1/00.jpg" alt="bg-1" />
      </div>
      <div className="section-2 col-12 col-md-10 mx-auto">
        <h3>Erstellung von Schalpläne</h3>
        <p>
          Ein Schalplan ist ein Werkplan für Bauwerke die ganz oder teilweise
          aus Beton bestehen. Im Schalplan wird maßstäblich und mit allen
          Details das spätere Bauteil dargestellt. Nach diesen Plänen wird die
          Schalung hergestellt, die selbst aber nicht dargestellt ist. Die
          Schalung ist die Negativform, in die auf einer Baustelle der flüssige
          Beton gegossen wird und in der er erhärtet.{" "}
        </p>
        <p>
          Schalpläne zeigen die Untersichten von Decken (im Gegensatz zum
          Architekten der die Draufsicht auf den Fußboden darstellt) und
          Draufsichten (Grundriss), Ansichten (Seitenrisse) und ggf. mehrere
          Schnitte der herzustellenden Beton- oder Stahlbetonbauten mit
          Bemaßung.
        </p>
        <p>
          Hochwertige Schal- und Bewehrungspläne führen zu einem reibungslosen
          Bauablauf und vermeiden zusätzliche Kosten bei der Bauausführung.Wir
          entwickeln aussagekräftige Konstruktionszeichnungen, Stahlbaupläne,
          Schalpläne und Bewehrungspläne für Hoch- und Ingenieurbauprojekte .
          Mit unserem geschulten Team von Konstrukteuren bieten wir unter
          Verwendung neuster Softwaretechnik herausragende Planungsqualität und
          ein beeindruckendes Preis-Leistungsverhältnis. Mithilfe unseres
          Qualitätssystems schaffen wir es, unser Know-How termingerecht und zu
          einem fairen Preis auf Papier zu bringen.
        </p>
        <p>Sie möchten Schal- und / oder Bewehrungspläne erstellen lassen? </p>
        <p>
          Dann schreiben Sie uns, um ein personalisiertes Angebot anzufordern.
          Nehmen Sie Kontakt mit uns auf und entdecken Sie weitere Vorteile.
        </p>
        <p>Unsere Kontaktdaten sehen Sie unten.</p>

        <h3 className="mt-3">Beispiel-/Referenzpläne:</h3>
        <Carousel
          className="mt-3"
          showIndicators
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
        >
          {plan1.map((image) => (
            <div>
              <img src={image.url} alt={`product-${image.id}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Plan;
