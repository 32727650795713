import React from "react";
import "./Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import Banners from "../components/Banner";

const Home = () => {
  const slideImages = Array(7)
    .fill(0)
    .map((_, i) => i + 1);

  return (
    <div className="home">
      <Banners />
      <div id="next-section" className="section-2 mt-3 mt-md-5">
        <div className="col-12 col-md-10 mx-auto mb-5 row">
          <div className="col-12 col-md-6">
            <h3>Was Machen Wir</h3>
            <p> Willkommen im Ingenieurbüro LOUP. </p>
            <p>
              Loup- Ingenieure – wir planen und realisieren Ihr Bauprojekt.
            </p>
            <p>
            Unser Büro unterstützt Sie mit über 35 Mitarbeitern auf dem Weg
              zur erfolgreichen Planung von Projekten in ganz Welt.
              Entscheidende Faktoren für die vertrauensvolle Zusammenarbeit sind
              unsere langjährige Erfahrung in der Realisierung von Großprojekten
              und unsere zielorientierte Arbeitsweise. Die Zielsetzung im
              Projekt entwickeln wir mit Ihnen gemeinsam und unsere Projektteams
              sorgen für ständige Transparenz bei der Kosten- und Terminplanung.
              So bieten wir eine starke Partnerschaft bei der Durchführung Ihrer
              Bauvorhaben.
            </p>
            <p>
              Im Mittelpunkt unserer Dienstleistungen steht die Erstellung von
              Schal- und Bewehrungspläne für den Hochbauten, Industriebauten,
              Brücken- und Ingenieurbau.
            </p>
            <p>
              Gerne informieren wir Sie in einem persönlichen Gespräch über
              unser Leistungsspektrum zu diesen Bereichen und erstellen für Sie
              Konzepte und Nachweise.
            </p>
          </div>
          <div className="col-12 col-md-6 text-center">
            <img className="img-fluid" src="/assets/images/home/asset1.png" />
          </div>
        </div>
        <div className="text-center col-11 col-md-10 col-lg-8 mx-auto">
          <h4>IHRE ZUFRIEDENHEIT IST UNSER MASSSTAB</h4>
        </div>
        <div className="my-3">
          <div className="col-11 col-md-10 col-lg-8 mx-auto">
            <div className="d-flex align-items-center justify-content-center">
              <div className="line col-4 p-0"></div>
              <h4 className="col-4 text-center diagram-title">
                Qualität schafft Vertrauen
              </h4>
              <div className="line col-4 p-0"></div>
            </div>
            <div className="row diagram">
              <div className="col-6 border-right p-0">
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-box p-1 p-md-3 p-1 col-8">
                    Wir stellen den Kunden so zufrieden, dass er noch ein
                    weiteres Mal mit uns bauen möchte.
                  </div>
                  <div className="line col-4"></div>
                </div>
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-box p-1 p-md-3 p-1 col-8">
                    Wir legen Wert auf qualifiziertes Fachpersonal.
                  </div>
                  <div className="line col-4"></div>
                </div>
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-box p-1 p-md-3 p-1 col-8">
                    Wir behandeln Güter und Ressourcen sorgfältig.
                  </div>
                  <div className="line col-4"></div>
                </div>
              </div>
              <div className="col-6 border-left p-0">
                <div className="space-box"></div>
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="line col-4"></div>
                  <div className="text-box p-1 p-md-3 col-8">
                    Ein vertrauensvoller Umgang mit unseren Kunden,
                    Mitarbeitern, Lieferanten und Nachunternehmern ist uns
                    wichtig.
                  </div>
                </div>
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="line col-4"></div>
                  <div className="text-box p-1 p-md-3 col-8">
                    Wir erstellen Qualität und kontrollieren diese fortwährend.
                  </div>
                </div>
                <div className="space-box"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="line col-4"></div>
                  <div className="text-box p-1 p-md-3 col-8">
                    Wir halten unsere vereinbarten Fertigstellungstermine ein
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
