import React, { useEffect, useReducer } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { Switch, Route, Redirect } from "react-router-dom";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import { initialState, reducer } from "./reducers";
import {
  changeServiceSelected,
  hideLoading,
  showLoading,
  loadProducts,
  loadPlanImages,
} from "./actions";
import AppContext from "./context/app-context";
import Plan from "./pages/Plan1";
import Plan2 from "./pages/Plan2";
import firebase from "firebase/app";
import "firebase/firebase-firestore";
import { FIRE_BASE_CONFIGS } from "./configs";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (!firebase.apps.length) {
    firebase.initializeApp(FIRE_BASE_CONFIGS);
  }

  let firebaseStore = firebase.firestore();

  const homeContext = {
    ...state,
    onShowLoading: () => showLoading(dispatch),
    onHideLoading: () => hideLoading(dispatch),
    onChangService: (service) => changeServiceSelected(dispatch, service),
    onLoadProjects: () => onLoadProjects(),
  };

  useEffect(() => {
    onLoadProjects();
    onLoadPlanImages();
  }, []);

  const onLoadProjects = async () => {
    const data = [];
    const snapshot = await firebaseStore.collection("projects").get();
    snapshot.forEach((doc) => data.push({ id: doc.id, ...doc.data() }));
    loadProducts(dispatch, data);
  };

  const onLoadPlanImages = async () => {
    let plan1 = {};
    let plan2 = {};
    const snapshot1 = await firebaseStore.collection("plan-1").get();
    const snapshot2 = await firebaseStore.collection("plan-2").get();
    snapshot1.forEach((item) => {
      plan1 = item.data();
    });
    snapshot2.forEach((item) => {
      plan2 = item.data();
    });
    loadPlanImages(dispatch, plan1.images, plan2.images);
  };

  return (
    <React.Fragment>
      <AppContext.Provider value={homeContext}>
        <Header />
        <Switch>
          <Route path="/startseite" component={Home} />
          <Route path="/schalpläne" component={Plan} />
          <Route path="/bewehrungspläne" component={Plan2} />
          <Route path="/projekte" component={Products} />
          <Route path="/kontakt" component={Contact} />
          <Redirect from="/" to="/startseite" />
        </Switch>
        <Footer />
      </AppContext.Provider>
    </React.Fragment>
  );
}

export default App;
