import React, { useState, useEffect } from "react";
import "./Header.scss";
import { NavLink, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [scrollToTop, setScrollToTop] = useState("hide");
  const [showMobileMenu, setShowMobileMenu] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", (event) => handleScroll(event));
    AOS.init({
      once: true, // whether animation should happen only once - while scrolling down,
      duration: 2000,
    });
    return window.removeEventListener("scroll", (event) => handleScroll(event));
  }, []);

  const handleScroll = ({ target }) => {
    if (target.body.scrollTop > 50) {
      setScrollToTop("show");
    } else {
      setScrollToTop("hide");
    }
  };

  const onScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onToggleMobileMenu = () => {
    setShowMobileMenu(showMobileMenu === "" ? "show-menu" : "");
  };

  return (
    <div className="header">
      <header
        id="header bg-white"
        className={`header-inner ${scrollToTop === "show" ? "fixed-top" : ""}`}
      >
        <nav className={`navbar mx-auto justify-content-between p-0`}>
          <Link className="navbar-brand col-8 col-md-2" to="/">
            <img className="img-fluid pb-3" src="/logo.png" alt="Logo" />
          </Link>
          <ul className="nav d-none d-md-flex align-items-center">
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/startseite"
              >
                STARTSEITE
              </NavLink>
            </li>
            <li className="nav-item">/</li>
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/schalpläne"
              >
                SCHALPLÄNE
              </NavLink>
            </li>
            <li className="nav-item">/</li>
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/bewehrungspläne"
              >
                BEWEHRUNGSPLÄNE
              </NavLink>
            </li>
            <li className="nav-item">/</li>
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/projekte"
              >
                PROJEKTE
              </NavLink>
            </li>
            <li className="nav-item">/</li>
            <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to="/kontakt"
              >
                KONTAKT
              </NavLink>
            </li>
          </ul>
          <a
            className={`cursor-pointer  d-flex  d-md-none ${
              showMobileMenu === "" ? "" : "menu-toggle"
            }`}
            onClick={onToggleMobileMenu}
          >
            <i
              className={`fa fa-2x ${
                showMobileMenu === "" ? "fa-bars" : "fa-times"
              }`}
            />
          </a>
        </nav>
        <div className={`header-fixed ${showMobileMenu}`}>
          <div className="header-mobile-inner">
            <div className="menu">
              <nav className="menu-background gradient text-left">
                <ul>
                  <li onClick={onToggleMobileMenu}>
                    <NavLink to="/startseite">STARTSEITE</NavLink>
                  </li>
                  <li onClick={onToggleMobileMenu}>
                    <NavLink to="/schalpläne">SCHALPLÄNE</NavLink>
                  </li>
                  <li onClick={onToggleMobileMenu}>
                    <NavLink to="/bewehrungspläne">BEWEHRUNGSPLÄNE</NavLink>
                  </li>
                  <li onClick={onToggleMobileMenu}>
                    <NavLink to="/projekte">PROJEKTE</NavLink>
                  </li>
                  <li onClick={onToggleMobileMenu}>
                    <NavLink to="/kontakt">KONTAKT</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <a
        onClick={onScrollToTop}
        className={`cursor-pointer fa fa-angle-up scroll-to-top d-none d-sm-block ${scrollToTop}`}
      />
    </div>
  );
};

export default Header;
