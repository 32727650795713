import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "./Plan.scss";
import AppContext from "../context/app-context";

const Plan2 = () => {
  const { plan2 } = useContext(AppContext);
  return (
    <div className="plans">
      <div className="section-1">
        <img src="/assets/images/plan2/00.jpg" alt="bg-1" />
      </div>
      <div className="section-2 col-12 col-md-10 mx-auto">
        <h3>Erstellung von Bewehrungspläne</h3>
        <p>
          Der Bewehrungsplan stellt die Bewehrung aus Betonstahl eines
          Stahlbetonbauteiles dar. Es sind reine Ausführungspläne, nach denen
          die Betonstähle in der Biegerei abgelängt sowie gebogen (Stahlauszug)
          und anschließend vom Betonbauer (Flechter) auf der Baustelle verlegt
          werden.
        </p>
        <p>
          Die Bewehrungspläne werden auf der Grundlage einer durch einen
          Bauingenieur zuvor erstellten Statik angefertigt. Bewehrungspläne
          werden durch Bauzeichner oder Bautechniker gezeichnet. Weitere
          Grundlage für den Bewehrungsplan ist meistens der dazugehörige
          Schalplan, der die Form des Stahlbetonbauteils darstellt.
        </p>
        <p>
          Hochwertige Schal- und Bewehrungspläne führen zu einem reibungslosen
          Bauablauf und vermeiden zusätzliche Kosten bei der Bauausführung. Wir
          entwickeln aussagekräftige Konstruktionszeichnungen, Stahlbaupläne,
          Schalpläne und Bewehrungspläne für Hoch- und Ingenieurbauprojekte .
          Mit unserem geschulten Team von Konstrukteuren bieten wir unter
          Verwendung neuster Softwaretechnik herausragende Planungsqualität und
          ein beeindruckendes Preis-Leistungsverhältnis. Mithilfe unseres
          Qualitätssystems schaffen wir es, unser Know-How termingerecht und zu
          einem fairen Preis auf Papier zu bringen.
        </p>
        <p>Sie möchten Schal- und/oder Bewehrungspläne erstellen lassen?</p>
        <p>
          Dann schreiben Sie uns, um ein personalisiertes Angebot anzufordern.
          Nehmen Sie Kontakt mit uns auf und entdecken Sie weitere Vorteile.
        </p>
        <p>Unsere Kontaktdaten sehen Sie unten.</p>
        <h3 className="mt-3">Beispiel-/Referenzpläne:</h3>
        <Carousel
          className="mt-3"
          showIndicators
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
        >
          {plan2.map((image) => (
            <div>
              <img src={image.url} alt={`product-${image.id}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Plan2;
