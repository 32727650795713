import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="row pr-1 pr-md-4">
        <div className="col-12 col-md-8 p-0 position-relative d-none d-md-block">
          <div className="title-box d-flex align-items-center">
            <h5 className="col-4 mb-0 font-weight-bold">UNTERNEHMEN</h5>
            <h5 className="col-6 mb-0 font-weight-bold">KONTAKT</h5>
          </div>
          <div className="box-1"></div>
          <div className="box-2"></div>
          <div className="col-2"></div>
          <div className="content d-flex">
            <div className="col-4">
              <NavLink to="/startseite">
                <h5>STARTSEITE</h5>
              </NavLink>
              <NavLink to="/schalpläne">
                <h5>SCHALPLÄNE</h5>
              </NavLink>
              <NavLink to="/bewehrungspläne">
                <h5>BEWEHRUNGSPLÄNE</h5>
              </NavLink>
              <NavLink to="/projekte">
                <h5>PROJEKTE</h5>
              </NavLink>
              <NavLink to="/kontakt">
                <h5>KONTAKT</h5>
              </NavLink>
            </div>
            <div className="col-8">
              <h5>Loup Engineering Co., Ltd - Konstruktionsbüro im Bauwesen</h5>
              <h5>Ho Chi Minh City, Vietnam</h5>
              <h5>
                Email :{" "}
                <a
                  className="text-white email"
                  href="mailto:info@loup-engineering.com"
                  target="_blank"
                >
                  info@loup-engineering.com
                </a>{" "}
                |{" "}
                <a
                  className="text-white email"
                  href="mailto:konstruktion@loup-engineering.com"
                  target="_blank"
                >
                  konstruktion@loup-engineering.com
                </a>
              </h5>
              <h5>Telf.: +84 936 449 539</h5>
              <h5>
                Web:{" "}
                <a
                  className="text-white email"
                  href="https://loup-ingenieure.de"
                  target="_blank"
                >
                  https://loup-ingenieure.de
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none mt-3 w-100">
          <div className="col-9 offset-1">
            <h5 className="font-weight-bold">UNTERNEHMEN</h5>
            <NavLink to="/startseite">
              <h5>STARTSEITE</h5>
            </NavLink>
            <NavLink to="/schalpläne">
              <h5>SCHALPLÄNE</h5>
            </NavLink>
            <NavLink to="/bewehrungspläne">
              <h5>BEWEHRUNGSPLÄNE</h5>
            </NavLink>
            <NavLink to="/projekte">
              <h5>PROJEKTE</h5>
            </NavLink>
            <NavLink to="/kontakt">
              <h5>KONTAKT</h5>
            </NavLink>
          </div>
          <hr style={{ background: "white" }} />
          <div className="col-9 offset-1">
            <h5 className="font-weight-bold">KONTAKT</h5>
            <h5>Loup Engineering Co., Ltd - Konstruktionsbüro im Bauwesen</h5>
            <h5>Ho Chi Minh City, Vietnam</h5>
            <h5>
              Email : info@loup-engineering.com I
              konstruktion@loup-engineering.com
            </h5>
            <h5>Telf.: +84 936 449 539</h5>
            <h5>Web: https://loup-ingenieure.de</h5>
          </div>
        </div>
        <div className="col-10 col-md-4 mx-auto p-2 p-md-4">
          <div class="google-maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.6115027037476!2d106.64851201526058!3d10.841013660961526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752998ccd02df9%3A0xa5f90e7158dcfb32!2zxJDGsOG7nW5nIHPhu5EgMjEsIHBoxrDhu51uZyA4LCBHw7IgVuG6pXAsIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1590194693530!5m2!1sen!2s"
              width="400"
              height="300"
              frameborder="0"
              style={{ border: 0 }}
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
