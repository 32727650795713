import React, { useState } from "react";
import "./Contact.scss";
import Snackbar from "@bit/mui-org.material-ui.snackbar";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const formSchema = Yup.object().shape({
  email: Yup.string()
    .required("Sie müssen Ihre E-Mail-Adresse eingeben")
    .email("Sie müssen eine gültige E-Mail-Adresse eingeben"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, () => "Sie müssen Ihre Telefonnummer eingeben")
    .required("Sie müssen eine gültige Telefonnummer eingeben"),
});
const Contact = () => {
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = React.useState(null);

  const onSubmit = async (values) => {
    const res = await window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "helios@hgmedia.vn",
      Password: "8B6404FC74DC6B59BD17678CDC16DFA72051",
      To: "info@loup-engineering.com",
      From: "helios@hgmedia.vn",
      Subject: `[loup-ingenieure.de] New Contact From ${values.email}`,
      Body: `<h3>${values.message}</h3><br/><h4>Email : ${values.email}</h4></br><h4>Phone : ${values.phoneNumber}</h4>`,
    });
    const message = res === "OK" ? "E-Mail-Erfolg senden" : res;
    setMessageInfo({ message, key: 1 });
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="products ">
      <div className="section-1">
        <img src="/assets/images/contact/00.jpg" alt="bg-1" />
      </div>
      <div className="section-2 col-12 col-md-10 row mx-auto">
        <div className="col-12 col-md-6">
          <h3>Nehmen Sie Kontakt zu uns auf:</h3>
          <h6 className="text-left">
            Sie können uns über die nachfolgenden Kontaktdaten erreichen oder
            nutzen sie einfach das Formular. Bitte füllen Sie alle mit einem *
            gekennzeichneten Felder vollständig aus. Vielen Dank.
          </h6>
          <h4 className="mt-4">Loup Engineering Co., Ltd</h4>
          <div>21 Straße, Bezirk Go Vap, Ho Chi Minh City, Vietnam</div>
          <div className="d-flex mt-2">
            <div className="col-2 pl-0">Email</div>
            <div className="col-8">
              <a
                className="text-dark email"
                href="mailto:info@loup-engineering.com"
                target="_blank"
              >
                : info@loup-engineering.com
              </a>
            </div>
          </div>
          <div className="d-flex mt-1">
            <div className="col-8 offset-2">
              <a
                className="text-dark email"
                href="mailto:konstruktion@loup-engineering.com"
                target="_blank"
              >
                : konstruktion@loup-engineering.com (Für Projektbeteiligte)
              </a>
            </div>
          </div>
          <div className="d-flex mt-1">
            <div className="col-2 pl-0">Internet</div>
            <div className="col-8">: https://loup-ingenieure.de </div>
          </div>
          <div className="d-flex mt-1">
            <div className="col-2 pl-0">Telf.</div>
            <div className="col-8">: +84 936 449 539</div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h6 className="text-left mt-3">
            Senden Sie uns eine Nachricht an{" "}
            <a
              className="text-dark email"
              href="mailto:info@loup-engineering.com"
              target="_blank"
            >
              : info@loup-engineering.com
            </a>{" "}
            oder nutzen Sie das untenstehende Kontaltformular.
          </h6>
          <div class="contact-form mb-2 mb-lg-5 mt-5">
            <Formik
              initialValues={{
                email: "",
                phoneNumber: "",
                message: "",
              }}
              onSubmit={onSubmit}
              validationSchema={formSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <div class="mt-4">
                    <Field
                      name="email"
                      className={`form-control ${
                        errors.email && touched.email && "is-invalid"
                      }`}
                      placeholder={"Email*"}
                    />
                    {errors.email && touched.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}
                  </div>
                  <div class="mt-4">
                    <Field
                      name="phoneNumber"
                      className={`form-control ${
                        errors.phoneNumber &&
                        touched.phoneNumber &&
                        "is-invalid"
                      }`}
                      placeholder={"Telefon*"}
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    ) : null}
                  </div>
                  <div class="d-flex w-100 mt-4">
                    <Field
                      as="textarea"
                      name="message"
                      placeholder={"Nachricht"}
                    />
                  </div>
                  <div class="d-flex mt-4 justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-default mb-5"
                      id="send-mail"
                    >
                      Senden
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <Snackbar
              key={messageInfo ? messageInfo.key : undefined}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <span id="message-id">
                  {messageInfo ? messageInfo.message : undefined}
                </span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
